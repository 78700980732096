import React from "react";
import { BsClipboardCheck as Board } from "react-icons/bs";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

const Notice = () => {
  const fetchNotices = async () => {
    const response = await fetch("https://server.sjs.edu.bd/allNotices");
    return response.json();
  };
  const { isLoading, data, error } = useQuery("noticeData", fetchNotices);
  return (
    <div className="container text-lg md:text-base space-y-3 md:ml-8 my-2">
      <h1 className="flex items-center">
        <Board className="text-2xl md:text-3xl mr-2" />
        <span className="text-2xl md:text-3xl border-b-2 border-black font-merriweather font-semibold">
          {" "}
          Notice Board
        </span>
      </h1>
      <div
        className={`space-y-3 md:space-y-4 ${
          data?.length > 4 ? "h-72 overflow-y-auto" : ""
        }`}
      >
        <div className="notice-format flex items-center font-lato font-semibold ">
                <div
                  className="bg-blue-700 rounded-lg text-white py-2 px-3 text-center mr-2"
                  key={5}
                >
                  <p className="text-sm">
                    4
                    <br />{" "}
                  July
                  </p>
                </div>
                <a
                href="https://drive.google.com/file/d/1Ij0MbkTW0LuTi6o7NNOumd-1zL3TtyDh/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                Notice for Book Distribution (Grades 3-8)
              </a>
        </div>
        <div className="notice-format flex items-center font-lato font-semibold ">
                <div
                  className="bg-blue-700 rounded-lg text-white py-2 px-3 text-center mr-2"
                  key={5}
                >
                  <p className="text-sm">
                    4
                    <br />{" "}
                  July
                  </p>
                </div>
                <a
                href="https://drive.google.com/file/d/1lN80SUp2lxaMXsZoRqrrOYmgMcub5pCu/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                Admission Test Result Grades 4 to 9
              </a>
        </div>
        <div className="notice-format flex items-center font-lato font-semibold ">
                <div
                  className="bg-blue-700 rounded-lg text-white py-2 px-3 text-center mr-2"
                  key={5}
                >
                  <p className="text-sm">
                    4
                    <br />{" "}
                  July
                  </p>
                </div>
                <a
                href="https://drive.google.com/file/d/1xD4rauuyXS6Fxn0RJmDiBZUnpjNNza6v/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                Admission Test Waiting List Grades 4 to 9
              </a>
        </div>
        {isLoading && (
          <div className="mt-4 text-center">
            <button
              type="button"
              className="rounded-lg bg-white-300 px-2 py-2"
              disabled
            >
              <svg
                className="h-7 w-7 animate-spin text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </button>
          </div>
        )}
        {error && <h2 className="text-center">Could not fetch data.</h2>}
      
        {data &&
          data.map((notice) => (
            <>
              <div className="notice-format flex items-center font-lato font-semibold ">
                <div
                  className="bg-blue-700 rounded-lg text-white py-2 px-3 text-center mr-2"
                  key={notice?._id}
                >
                  <p className="text-sm">
                    {new Date(notice?.date).toLocaleString("en-us", {
                      day: "numeric",
                    })}{" "}
                    <br />{" "}
                    {new Date(notice?.date).toLocaleString("en-us", {
                      month: "short",
                    })}
                  </p>
                </div>
                <Link to={`/noticeDetails/${notice?._id}`}>
                  {notice?.title}
                </Link>
              </div>
            </>
          ))}
          
      </div>
    </div>
  );
};

export default Notice;
