import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer/Footer";
import Navbar from "../Shared/Header/Navbar";
import { TiHomeOutline as Home } from "react-icons/ti";

const AboutSchool = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="bg-gray-100">
        <Navbar />
        <h1 className="header-style font-merriweather mb-0">Prospectus</h1>
        <div className="font-merriweather p-6 md:p-7 bg-white rounded-lg shadow-xl space-y-5 m-5 text-lg font-base md:font-medium leading-loose text-left md:text-justify">
          <p className="text-2xl md:text-5xl text-center my-2 font-semibold text-blue-900 leading-relaxed pt-0">
            Advancing in Wisdom and Virtue
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            St. Joseph International School is founded and conducted by the
            Brothers of Holy Cross, a Roman Catholic Religious Congregation, who
            are committed to teaching and education related mission. Bro. Jude
            Costello, CSC founded the School in the year 1954 at 32, Shah Shaheb
            Lane, Narinda. The School was shifted to 97, Asad Avenue,
            Mohammadpur in the year 1964. In the beginning, it was an English
            Medium Cambridge School and in 1972 following the Government
            decision, it was changed to a Bengali Medium Secondary School under
            the Board of Intermediate and Secondary Education, Dhaka. In 1999
            the School opened the Higher Secondary Section in English Medium
            (Science) class and in 2001 Bengali Medium (Science and Business
            Studies) class and in 2004 Humanities Group. In 2004 the school
            opened an English Medium (NCTB curriculum) section in class VI. In
            each class, we have three sections and of them one is English
            Medium. The then management has decided to expand education services
            further with introduction of Cambridge curriculum from July 2017.
            Courses to be taught in this institution would lead to University of
            Cambridge International Examination (CIE), under the UK Education
            Board. The present management decided to open the second campus of
            St Joseph International School at Narinda, where St Joseph
            International School was originally founded. It is our great
            pleasure to introduce the 2nd campus of SJIS.
          </p>
          <p className="indent-6 font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            The aim of the St. Joseph International School is to aid students to
            develop a mature sense of obligation to God, the country, and their
            fellow men; scholastic proficiency through disciplined studies;
            power to make utmost use of print and other media; the ability to
            co-operate with their fellow students in all phases of life. In
            another word, we want to educate their mind, heart and soul with
            proper human values. Since the institution alone cannot take the
            responsibility of educating a child, it is absolutely essential that
            the parents/guardians and the community share the responsibility of
            educating a child.
          </p>
        </div>
        <div className="flex justify-center md:mb-5">
          <Link to="/">
            <button className="button-style">
              <Home className="text-xl mr-1" />
              Home
            </button>
          </Link>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AboutSchool;
