import React from "react";
import Marquee from "react-fast-marquee";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

const ScrollBar = () => {
  const fetchNotices = async () => {
    const response = await fetch("https://server.sjs.edu.bd/allNotices");
    return response.json();
  };
  const { isLoading, data, error } = useQuery("noticeData", fetchNotices);
  return (
    <>
      <Marquee
        speed={40}
        pauseOnClick={true}
        pauseOnHover={true}
        gradient={false}
      >
        {isLoading && (
          <div className="text-center">
            <button
              type="button"
              className="rounded-lg bg-white-300 px-2 py-2"
              disabled
            >
              <svg
                className="h-7 w-7 animate-spin text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </button>
          </div>
        )}
        {error && <h2 className="text-center">Could not fetch data.</h2>}
        {data &&
          data.map((notice) => (
            <Link
              key={notice?._id}
              className="mr-3 text-semibold text-lg md:text-2xl decoration-0 text-blue-600"
              to={`/noticeDetails/${notice?._id}`}
            >
              {notice?.title}
              <span>,</span>
            </Link>
          ))}
      </Marquee>
    </>
  );
};

export default ScrollBar;
