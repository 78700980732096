import React from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

const AddEvent = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    fetch("https://server.sjs.edu.bd/addEvent", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.insertedId) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Event Added Successfully",
            showConfirmButton: false,
            timer: 2000,
          });
          reset();
        }
      });
  };
  return (
    <div className="w-5/6 lg:max-w-3xl mx-auto font-inter">
      <h2 className="my-3 text-3xl font-black text-center">Post A New Event</h2>
      <div className="border rounded-lg border-slate-400">
        <form
          className="mb-0 space-y-1 w-5/6 mx-auto"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="py-2">
            <label htmlFor="title" className="label-style">
              Event Title:
            </label>
            <div className="mt-1">
              <input
                id="title"
                type="text"
                className="input-style"
                {...register("title", { required: true })}
              />
              {errors.title && (
                <p className="text-red-500 mt-2">*Event Title is required.</p>
              )}
            </div>
          </div>
          <div className="py-2">
            <label htmlFor="date" className="label-style">
              Date:
            </label>
            <div className="mt-1">
              <input
                id="date"
                type="date"
                className="input-style"
                {...register("date", { required: true })}
              />
              {errors.date && (
                <p className="text-red-500 mt-2">*Please pick a Date.</p>
              )}
            </div>
          </div>
          <div className="py-2">
            <label htmlFor="date" className="label-style">
              Event Link:
            </label>
            <input
              id="link"
              type="text"
              className="input-style"
              placeholder="Enter the Link of the event"
              {...register("link", { required: true })}
            />
            {errors.link && (
              <p className="text-red-500 mt-2">
                *Please enter the link of the event.
              </p>
            )}
          </div>
          <div className="text-center">
            <input
              className="mb-3 mt-2 bg-teal-700 px-4 py-3 text-white rounded-lg hover:bg-teal-800"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEvent;
