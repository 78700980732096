import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "./customslider.css";
import Main from "../../Assets/main.jpg";
import FieldView from "../../Assets/viewFromField.jpg";
import FieldView2 from "../../Assets/viewFromField2.jpg";
import Field from "../../Assets/field.jpg";
const Slider = () => {
  return (
    <div className="mx-auto ">
      <Swiper
        slidesPerView={1}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        autoplay={{ delay: 2000 }}
        modules={[Pagination, Navigation, Autoplay, EffectFade]}
        className="mySwiper"
        effect="fade"
      >
        <SwiperSlide>
          <img className="h-[16.25rem] md:h-[46rem] w-full" src={Main} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="h-[16.25rem] md:h-[46rem] w-full"
            src={Field}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="h-[16.25rem] md:h-[46rem] w-full"
            src={FieldView}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="h-[16.25rem] md:h-[46rem] w-full"
            src={FieldView2}
            alt=""
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Slider;
