import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const ManageNotices = () => {
  const [noticeData, setNoticeData] = useState([]);
  const [isRefetching, setRefetching] = useState(null);

  useEffect(() => {
    fetch("https://server.sjs.edu.bd/allNotices")
      .then((res) => res.json())
      .then((data) => setNoticeData(data));
  }, [isRefetching]);

  const count = noticeData.length;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Do you want to delete?",
      text: "This action cannot be reversed!",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`http://server.sjs.edu.bd/deleteNotice/${id}`, {
          method: "DELETE",
          headers: { "content-type": "application/json" },
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.deletedCount) {
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                text: "Notice has been deleted.",
                timer: 2000,
              });
              setRefetching(true);
            }
          });
      }
    });
  };
  return (
    <div className="font-inter mx-auto p-5">
      <h1 className="mb-3 font-extrabold text-3xl text-center">
        Manage Notices
      </h1>
      <div className="shadow-2xl">
        <table className="table-fixed text-center border-collapse divide-y divide-gray-500">
          <thead>
            <tr className="bg-gray-600 text-white">
              <th className="p-3 text-sm md:text-lg font-semibold tracking-wide">
                Date
              </th>
              <th className="p-3 text-sm md:text-lg font-semibold tracking-wide">
                Title
              </th>
              <th className="p-3 text-sm md:text-lg font-semibold tracking-wide">
                Edit
              </th>
              <th className="p-3 text-sm md:text-lg font-semibold tracking-wide">
                Delete
              </th>
            </tr>
          </thead>
          {noticeData.map((notice) => (
            <tbody key={notice?._id}>
              <tr className="cursor-pointer bg-gray-50">
                <td className="p-3 text-sm md:text-lg text-left">
                  {notice?.date}
                </td>
                <td className="p-3 text-sm md:text-lg text-left">
                  {notice?.title}
                </td>
                <td className="p-3">
                  <Link to={`/updateNoticeInfo/${notice?._id}`}>
                    <button className="text-sm md:text-base mt-2 px-5 py-2 border rounded-full text-white bg-emerald-600 hover:bg-emerald-700 inline-flex items-center justify-items-center">
                      Edit
                    </button>
                  </Link>
                </td>
                <td className="p-3">
                  <button
                    onClick={() => handleDelete(notice._id)}
                    className="text-sm md:text-base mt-2 px-3 py-2 border rounded-full text-white bg-red-600 hover:bg-red-700 inline-flex items-center justify-items-center"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
      {!count && (
        <div className="mt-4 text-center">
          <button
            type="button"
            className="rounded-lg bg-white-300 px-2 py-2"
            disabled
          >
            <svg
              className="h-7 w-7 animate-spin text-blue-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default ManageNotices;
