import React, { useEffect } from "react";
import NewsAndEvents from "../NewsAndEvents/NewsAndEvents";
import Notice from "../Notice/Notice";
import ScrollBar from "../Scrollbar/Scrollbar";
import Footer from "../Shared/Footer/Footer";
import Navbar from "../Shared/Header/Navbar";
import Slider from "../Slider/Slider";
import BannerPicture from "../../Assets/cover.jfif";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <ScrollBar />
      <div>
        <img
          src={BannerPicture}
          className="w-screen my-2"
          alt="Admission Banner 2023"
        />
      </div>
      <Slider />
      <div className="md:flex justify-between mt-3 lg:mt-6 lg:container">
        <Notice />
        <NewsAndEvents />
      </div>
      <Footer />
    </>
  );
};

export default Home;
