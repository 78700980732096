import React from "react";
import { Link } from "react-router-dom";
import { MdLocationOn as Location } from "react-icons/md";
import { RiFacebookFill as Facebook } from "react-icons/ri";
import { FiPhoneIncoming as Phone } from "react-icons/fi";
import { MdOutlineHistoryEdu as About } from "react-icons/md";
import { SiGnuprivacyguard as Privacy} from "react-icons/si";
import Logo from "../../../Assets/logo.png";

const Footer = () => {
  const iframe_style = {
    border: "none",
    overflow: "hidden",
  };
  return (
    <div className="grid md:grid-cols-3 grid-cols-1 justify-items-center content-center bg-black gap-1 p-4 mt-3 text-white border-t-4 border-t-blue-600">
      <div className="flex flex-col p-2 self-center space-y-2 font-lato">
        <img
          className="h-28 md:h-40 w-28 md:w-40 mx-auto"
          src={Logo}
          alt="brand"
        />
        <h1 className="text-xl md:text-sm xl:text-2xl font-bold mx-auto">
          St. Joseph International School
        </h1>
        <div className="flex items-center">
          <Location className="text-base" />
          <span className="text-sm xl:text-lg mr-1">
            97 Asad Avenue, Mohammadpur, Dhaka-1207.
          </span>
        </div>
      </div>
      {
        <iframe
          title="Facebook Page Feed"
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSJIS17&tabs=timeline&width=300&height=350&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
          height="350"
          className="sm:w-72 xl:w-[19rem]"
          style={iframe_style}
          scrolling="no"
          frameBorder="0"
          allowFullScreen={true}
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>
      }
      <div className="space-y-3 mt-2 md:mt-0 text-sm md:text-base font-medium self-center flex flex-col font-lato">
        <h2 className="text-lg border-b-2 ">Contact</h2>
        <div className="flex items-center">
          <Phone className="text-lg mr-2" />
          <p>Phone: 01793-970729</p>
        </div>
        <div className="flex items-center">
          <Facebook className="text-xl mr-1" />
          <a
            href="https://www.facebook.com/SJIS17/"
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
        </div>
        <div className="flex items-center">
          <About className="text-xl mr-1" />
          <Link to="/aboutSchool">About</Link>
        </div>
        <div className="flex items-center">
          <Privacy className="text-lg mr-1" />
          <Link to="/appPrivacyPolicy">Privacy Policy</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
