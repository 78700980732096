import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { VscChromeClose } from "react-icons/vsc";
import Logo from "../../../Assets/logo.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Navbar = () => {
  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 1000,
      easing: "ease",
    });
  });
  const [mobileMenu, setMobileMenu] = useState(false);

  const items = [
    { name: "Home", to: "/" },
    { name: "Faculty", to: "/faculty" },
    { name: "About School", to: "/aboutSchool" },
    { name: "Vision and Mission", to: "/visionAndMission" },
    { name: "Student Support", to: "/studentSupport" },
    // { name: "Privacy Policy", to: "/AppPrivacyPolicy" },
    { name: "Login", to: "/login" },
  ];

  return (
    <header className="shadow-md sticky top-0 z-50">
      <div className="px-3 py-4 lg:pb-2 bg-lightBlue text-lg font-semibold text-white">
        <div className="flex gap-x-4 justify-between items-center">
          <Link to="/">
            <div className="flex items-center">
              <img
                className="h-20 md:h-[6.5rem] w-20 md:w-[6.5rem] ml-1 mr-2"
                src={Logo}
                alt="brand"
              />
              <h1 className="text-3xl md:text-4xl lg:text-3xl xl:text-4xl font-medium ml-1 font-playfair">
                St. Joseph International School
              </h1>
            </div>
          </Link>

          {/*========== dynamic icon ==========*/}

          <button
            onClick={() => setMobileMenu(!mobileMenu)}
            className="lg:hidden p-2 border-2 border-white focus:ring-4 ring-offset-1 ring-white transition duration-700 ease-in rounded-lg"
          >
            {mobileMenu ? (
              <VscChromeClose className="text-2xl" />
            ) : (
              <HiOutlineMenuAlt3 className="text-2xl" />
            )}
          </button>

          <div className="hidden lg:flex items-center">
            <nav className="flex items-center text-sm lg:text-base xl:text-lg gap-x-4 font-merriweather text-center">
              {items?.map((item, index) => (
                <NavLink
                  className={(info) =>
                    info.isActive
                      ? "text-white font-bold border-b-2 border-white"
                      : ""
                  }
                  key={index}
                  to={item?.to}
                >
                  <h1>{item?.name}</h1>
                </NavLink>
              ))}
            </nav>
          </div>
        </div>

        {/*============== mobile menu =============*/}

        {mobileMenu && (
          <div
            data-aos="zoom-in-right"
            className="lg:hidden flex flex-col z-50 relative font-merriweather"
          >
            {items?.map((item, index) => (
              <NavLink
                className={(info) =>
                  info.isActive
                    ? "text-white font-bold border-b-2 border-white"
                    : ""
                }
                key={index}
                to={item?.to}
              >
                <h1 className="px-2 py-2">{item?.name}</h1>
              </NavLink>
            ))}
          </div>
        )}
      </div>
    </header>
  );
};

export default Navbar;
