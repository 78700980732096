import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Footer from "../Shared/Footer/Footer";
import Navbar from "../Shared/Header/Navbar";
import { TiArrowBack as Back, TiHomeOutline as Home } from "react-icons/ti";
//https://server.sjs.edu.bd/
//https://server.sjs.edu.bd
const TeacherProfile = () => {
  const { id } = useParams();
  const [singleTeacherData, setSingleTeacherData] = useState({});
  useEffect(() => {
    fetch(`https://server.sjs.edu.bd/singleTeacherData/${id}`)
      .then((res) => res.json())
      .then((data) => setSingleTeacherData(data));
  }, [id]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    name,
    email,
    honours,
    masters,
    bed,
    phd,
    experience,
    philosophy,
    skills,
    imagePath,
  } = singleTeacherData;

  return (
    <>
      <div className="bg-gray-100">
        <Navbar />
        <h1 className="header-style mb-2">Teacher Profile</h1>
        {!name ? (
          <div className="mt-5 text-center min-h-full">
            <button
              type="button"
              className="rounded-lg bg-white-300 px-2 py-2"
              disabled
            >
              <svg
                className="h-8 w-8 md:h-10 md:w-10 animate-spin text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </button>
          </div>
        ) : (
          <div className="container grid justify-items-center lg:grid-cols-3 mt-5">
            <div className="m-2 w-full bg-white rounded-lg drop-shadow-lg space-y-3 md:space-y-6 lg:col-span-2 font-merriweather p-4">
              <p className="text-2xl text-blue-900 text-center font-semibold">
                {name}
              </p>
              <p>
                <span className="font-semibold">Email:</span> {email}
              </p>
              <p>
                <span className="font-semibold">Honours: </span>
                {honours}
              </p>
              {masters && (
                <p>
                  <span className="font-semibold">Masters:</span> {masters}
                </p>
              )}
              {bed && (
                <p>
                  <span className="font-semibold">
                    Bachelor of Education (B.Ed):
                  </span>{" "}
                  {bed}
                </p>
              )}
              {phd && (
                <p>
                  <span className="font-semibold">PhD:</span> {phd}
                </p>
              )}
              <p>
                <span className="font-semibold">Teaching Philosophy:</span>{" "}
                {philosophy}
              </p>
              <p>
                <span className="font-semibold">Skills:</span> {skills}
              </p>
              <p>
                <span className="font-semibold">Experience at SJIS:</span>{" "}
                {experience}
              </p>
            </div>
            <div className="order-first lg:order-last flex justify-center items-center">
              <img
                src={`https://server.sjs.edu.bd/${imagePath}`}
                alt=""
                className="h-60 w-60 md:h-72 md:w-72 xl:h-80 xl:w-80 rounded-full ring-2 ring-slate-900 md:mt-3"
              />
            </div>
          </div>
        )}
        <div className="flex justify-center md:mb-5">
          <Link to="/faculty">
            <button className="mr-4 mt-2 px-5 py-2 border rounded-full text-lg text-white bg-emerald-600 hover:bg-emerald-700 inline-flex items-center justify-items-center">
              <Back className="text-xl mr-1" />
              Back
            </button>
          </Link>
          <Link to="/">
            <button className="mt-2 px-3 py-2 border rounded-full text-lg text-white bg-blue-600 hover:bg-blue-700 inline-flex items-center justify-items-center">
              <Home className="text-xl mr-1" />
              Home
            </button>
          </Link>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TeacherProfile;
