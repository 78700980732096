import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../Shared/Footer/Footer";
import Navbar from "../Shared/Header/Navbar";

const NoticeDetails = () => {
  const { id } = useParams();
  const [singleEvent, setSingleEvent] = useState({});
  //
  useEffect(() => {
    fetch(`https://server.sjs.edu.bd/singleNoticeDetail/${id}`)
      .then((res) => res.json())
      .then((data) => setSingleEvent(data));
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { title, link } = singleEvent;
  const previewLink = link?.replace("view?usp=sharing", "preview");
  return (
    <>
      <Navbar />
      {!link ? (
        <div className="mt-5 text-center min-h-full">
          <button
            type="button"
            className="rounded-lg bg-white-300 px-2 py-2"
            disabled
          >
            <svg
              className="h-8 w-8 md:h-10 md:w-10 animate-spin text-blue-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </button>
        </div>
      ) : (
        <div className="container mx-auto">
          <h2 className="header-style text-3xl font-merriweather">{title}</h2>
          <div className="p-2 mt-2">
            <iframe
              className="mx-auto w-full md:w-4/5 h-[28rem] md:h-[40rem]"
              title={title}
              src={previewLink}
              allow="autoplay"
            />
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default NoticeDetails;
