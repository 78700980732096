import React from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
//https://server.sjs.edu.bd
//https://server.sjs.edu.bd
const AddNotice = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("date", data.date);
    formData.append("pdf", data.pdf[0]);
    fetch("https://server.sjs.edu.bd/addNotice", {
      method: "POST",
      // headers: { "content-type": "application/json" },
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.insertedId) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Notice Posted Successfully",
            showConfirmButton: false,
            timer: 2000,
          });
          reset();
        }
      });
  };

  return (
    <div className="w-5/6 lg:max-w-3xl mx-auto font-inter">
      <h2 className="my-3 text-3xl font-black text-center">
        Post A New Notice
      </h2>
      <div className="border rounded-lg border-slate-400">
        <form
          className="mb-0 space-y-1 w-5/6 mx-auto"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="py-2">
            <label htmlFor="title" className="label-style">
              Notice Title:
            </label>
            <div className="mt-1">
              <input
                id="title"
                type="text"
                className="input-style"
                {...register("title", { required: true })}
              />
              {errors.title && (
                <p className="text-red-500 mt-2">*Notice Title is required.</p>
              )}
            </div>
          </div>
          <div className="py-2">
            <label htmlFor="date" className="label-style">
              Date:
            </label>
            <div className="mt-1">
              <input
                id="date"
                type="date"
                className="input-style"
                {...register("date", { required: true })}
              />
              {errors.date && (
                <p className="text-red-500 mt-2">*Please pick a Date.</p>
              )}
            </div>
          </div>
          <div className="p-2">
            <label htmlFor="pdf" className="label-style">
              Notice File (Only PDF files are accepted):
              <sup className="text-red-600 no-underline">*</sup>
            </label>
            <div className="mt-1">
              <input
                id="pdf"
                type="file"
                accept="application/pdf"
                className=" file:text-white file:cursor-pointer file:px-4 file:py-2 file:rounded-full file:bg-blue-500 file:hover:bg-blue-600 rounded-full"
                {...register("pdf", {
                  required: true,
                })}
              />
              {errors.pdf && (
                <p className="text-red-500 mt-2">
                  *Please enter the pdf of the notice.
                </p>
              )}
            </div>
          </div>
          <div className="text-center">
            <input
              className="mb-3 mt-2 bg-teal-700 px-4 py-3 text-white rounded-lg hover:bg-teal-800"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNotice;
