import React from "react";
import Navbar from "../Shared/Header/Navbar";
import Principal from "../../../src/Assets/Administration/1.-Br.Chandan-Gomes-CSC.jpg";
import VicePrincipal from "../../../src/Assets/Administration/Victor.jfif";
import DirectorAcademic from "../../../src/Assets/Administration/Shariful Anwar.JPG";
import MijanurRahman from "../../../src/Assets/Sports Teachers/1.-Mijanur-Rahman.jpg";
import MasudurRahman from "../../../src/Assets/Sports Teachers/2.-Masudur-Rahman-Rony.jpg";
import NejamUddin from "../../../src/Assets/Sports Teachers/3.-Nejam-Uddin.jpg";
import Jahanara from "../../../src/Assets/Sports Teachers/4.-Jahanara-Mukta.jpg";
import PronoyDaniel from "../../../src/Assets/Office Staff/1.-Pronoy-Danial-Dores.jpg";
import Danny from "../../../src/Assets/Office Staff/2.-Danny-Rodrizues.jpg";
import RomelThomas from "../../../src/Assets/Office Staff/4.-Romel-Thomas-Rozario.jpg";
import OrthiGomes from "../../../src/Assets/Office Staff/3.-Orthi-Gomes.jpg";
import TeacherData from "../TeacherData/TeacherData";
import { MdEmail as Email } from "react-icons/md";
import Footer from "../Shared/Footer/Footer";
import { useQuery } from "react-query";

const Faculty = () => {
  const fetchTeacherData = async () => {
    const response = await fetch("https://server.sjs.edu.bd/allTeachersData");
    return response.json();
  };
  const { isLoading, data, error } = useQuery("teachersData", fetchTeacherData);
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  //https://server.sjs.edu.bd
  //https://server.sjs.edu.bd
  return (
    <>
      <div className="bg-gray-100">
        <Navbar />
        <h2 className="header-style mb-2">Administration</h2>
        <div className="container grid md:grid-cols-2 lg:grid-cols-3 md:gap-1 xl:gap-x-0 justify-around">
          <div className="h-[21rem] md:h-[22.5rem] xl:h-[26.5rem] w-80 xl:w-[22rem] mx-auto lg:mr-12 p-4 md:p-5 bg-white rounded-lg shadow-xl space-y-2 mt-5">
            <img
              src={Principal}
              alt=""
              className="h-60 w-60 xl:h-80 xl:w-80 md:h-64 md:w-64 mx-auto rounded-full ring-2 ring-slate-900"
            />
            <div className="text-center font-merriweather">
              <div className="text-lg text-black font-semibold space-y-2 lg:space-y-3">
                <p>Br. Chandon Gomes, CSC</p>
                <p className="mt-2">Principal</p>
              </div>
            </div>
          </div>
          <div className="h-[21rem] md:h-[22.5rem] xl:h-[26.5rem] w-80 xl:w-[22rem] mx-auto lg:mr-12 p-4 md:p-5 bg-white rounded-lg shadow-xl space-y-2 mt-5">
            <img
              src={VicePrincipal}
              alt=""
              className="h-60 w-60 xl:h-80 xl:w-80 md:h-64 md:w-64 mx-auto rounded-full ring-2 ring-slate-900"
            />
            <div className="text-center font-merriweather">
              <div className="text-lg text-black font-semibold space-y-2 lg:space-y-3">
                <p>Br. Victor Bikash D'Rozario, CSC</p>
                <p className="mt-2">Vice-Principal</p>
              </div>
            </div>
          </div>
          <div className="h-[21rem] md:h-[22.5rem] xl:h-[26.5rem] w-80 xl:w-[22rem] mx-auto lg:ml-16 p-4 md:p-5 bg-white rounded-lg shadow-xl space-y-2 mt-5">
            <img
              src={DirectorAcademic}
              alt=""
              className="h-60 w-60 xl:h-80 xl:w-80 md:h-64 md:w-64 mx-auto rounded-full ring-2 ring-slate-900"
            />
            <div className="text-center font-merriweather">
              <div className="text-lg text-black font-semibold space-y-2 lg:space-y-3">
                <p>Shariful Anwar</p>
                <p className="mt-2">Director (Academic)</p>
              </div>
            </div>
          </div>
        </div>
        <h2 className="header-style mt-3">Teachers</h2>

        {isLoading && (
          <div className="mt-4 text-center">
            <button
              type="button"
              className="rounded-lg bg-white-300 px-2 py-2"
              disabled
            >
              <svg
                className="h-7 w-7 animate-spin text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </button>
          </div>
        )}

        {error && <h2 className="text-center">Could not fetch data.</h2>}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 md:gap-1 xl:gap-x-0 justify-center">
          {data &&
            data.map((teacher) => (
              <TeacherData key={teacher._id} teacher={teacher}></TeacherData>
            ))}
        </div>

        <h2 className="header-style mt-3">Sports Teachers</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 justify-center">
          <div className="h-[21.5rem] md:h-[22.5rem] lg:h-[23rem] xl:h-[26.5rem] w-80 xl:w-[22rem] mx-auto p-4 md:p-5 bg-white rounded-lg shadow-xl space-y-2 mt-5">
            <img
              src={MijanurRahman}
              alt=""
              className="h-60 w-60 xl:h-80 xl:w-80 md:h-64 md:w-64 mx-auto rounded-full ring-2 ring-slate-900"
            />
            <div className="text-center font-merriweather">
              <div className="text-lg text-black font-semibold space-y-2 lg:space-y-3">
                <p>Md. Mijanur Rahman</p>
                <div className="flex justify-center items-center">
                  <Email className="text-3xl md:text-3xl mr-1" />
                  <p>mijanur@sjs.edu.bd</p>
                </div>
              </div>
            </div>
          </div>
          <div className="h-[21.5rem] md:h-[22.5rem] lg:h-[23rem] xl:h-[26.5rem] w-80 xl:w-[22rem] mx-auto p-4 md:p-5 bg-white rounded-lg shadow-xl space-y-2 mt-5">
            <img
              src={MasudurRahman}
              alt=""
              className="h-60 w-60 xl:h-80 xl:w-80 md:h-64 md:w-64 mx-auto rounded-full ring-2 ring-slate-900"
            />
            <div className="text-center font-merriweather">
              <div className="text-lg text-black font-semibold space-y-2 lg:space-y-3">
                <p>Md. Masudur Rahman Rony</p>
                <div className="flex justify-center items-center">
                  <Email className="text-3xl md:text-3xl mr-1" />
                  <p>md.masudur@sjs.edu.bd</p>
                </div>
              </div>
            </div>
          </div>
          <div className="h-[21.5rem] md:h-[22.5rem] lg:h-[23rem] xl:h-[26.5rem] w-80 xl:w-[22rem] mx-auto p-4 md:p-5 bg-white rounded-lg shadow-xl space-y-2 mt-5">
            <img
              src={NejamUddin}
              alt=""
              className="h-60 w-60 xl:h-80 xl:w-80 md:h-64 md:w-64 mx-auto rounded-full ring-2 ring-slate-900"
            />
            <div className="text-center font-merriweather">
              <div className="text-lg text-black font-semibold space-y-2 lg:space-y-3">
                <p>Md. Nejam Uddin Faraji</p>
                <div className="flex justify-center items-center">
                  <Email className="text-3xl md:text-3xl mr-1" />
                  <p>md.nejam@sjs.edu.bd</p>
                </div>
              </div>
            </div>
          </div>
          <div className="h-[21.5rem] md:h-[22.5rem] lg:h-[23rem] xl:h-[26.5rem] w-80 xl:w-[22rem] mx-auto p-4 md:p-5 bg-white rounded-lg shadow-xl space-y-2 mt-5">
            <img
              src={Jahanara}
              alt=""
              className="h-60 w-60 xl:h-80 xl:w-80 md:h-64 md:w-64 mx-auto rounded-full ring-2 ring-slate-900"
            />
            <div className="text-center font-merriweather">
              <div className="text-lg text-black font-semibold space-y-2 lg:space-y-3">
                <p>Jahanara Khatun Mukta</p>
                <div className="flex justify-center items-center">
                  <Email className="text-3xl md:text-3xl mr-1" />
                  <p>mukta@sjs.edu.bd</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2 className="header-style mt-3">Office Staff</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 justify-center">
          <div className="h-[21.5rem] md:h-[22.5rem] lg:h-[23rem] xl:h-[26.5rem] w-80 xl:w-[22rem] mx-auto p-4 md:p-5 bg-white rounded-lg shadow-xl space-y-2 mt-5">
            <img
              src={PronoyDaniel}
              alt=""
              className="h-60 w-60 xl:h-80 xl:w-80 md:h-64 md:w-64 mx-auto rounded-full ring-2 ring-slate-900"
            />
            <div className="text-center font-merriweather">
              <div className="text-lg text-black font-semibold space-y-2 lg:space-y-3">
                <p>Pronoy Daniel Dores</p>
                <div className="flex justify-center items-center">
                  <Email className="text-3xl md:text-3xl mr-1" />
                  <p>pronoy@sjs.edu.bd</p>
                </div>
              </div>
            </div>
          </div>
          <div className="h-[21.5rem] md:h-[22.5rem] lg:h-[23rem] xl:h-[26.5rem] w-80 xl:w-[22rem] mx-auto p-4 md:p-5 bg-white rounded-lg shadow-xl space-y-2 mt-5">
            <img
              src={Danny}
              alt=""
              className="h-60 w-60 xl:h-80 xl:w-80 md:h-64 md:w-64 mx-auto rounded-full ring-2 ring-slate-900"
            />
            <div className="text-center font-merriweather">
              <div className="text-lg text-black font-semibold space-y-2 lg:space-y-3">
                <p>Daniel Danny Rodrigues</p>
                <div className="flex justify-center items-center">
                  <Email className="text-3xl md:text-3xl mr-1" />
                  <p>danny@sjs.edu.bd</p>
                </div>
              </div>
            </div>
          </div>
          <div className="h-[21.5rem] md:h-[22.5rem] lg:h-[23rem] xl:h-[26.5rem] w-80 xl:w-[22rem] mx-auto p-4 md:p-5 bg-white rounded-lg shadow-xl space-y-2 mt-5">
            <img
              src={RomelThomas}
              alt=""
              className="h-60 w-60 xl:h-80 xl:w-80 md:h-64 md:w-64 mx-auto rounded-full ring-2 ring-slate-900"
            />
            <div className="text-center font-merriweather">
              <div className="text-lg text-black font-semibold space-y-2 lg:space-y-3">
                <p>Romel Thomas Rozario</p>
                <div className="flex justify-center items-center">
                  <Email className="text-3xl md:text-3xl mr-1" />
                  <p>romel@sjs.edu.bd</p>
                </div>
              </div>
            </div>
          </div>
          <div className="h-[21.5rem] md:h-[22.5rem] lg:h-[23rem] xl:h-[26.5rem] w-80 xl:w-[22rem] mx-auto p-4 md:p-5 bg-white rounded-lg shadow-xl space-y-2 mt-5">
            <img
              src={OrthiGomes}
              alt=""
              className="h-60 w-60 xl:h-80 xl:w-80 md:h-64 md:w-64 mx-auto rounded-full ring-2 ring-slate-900"
            />
            <div className="text-center font-merriweather">
              <div className="text-lg text-black font-semibold space-y-2 lg:space-y-3">
                <p>Orthi Clodia Gomes</p>
                <div className="flex justify-center items-center">
                  <Email className="text-3xl md:text-3xl mr-1" />
                  <p>orthi@sjs.edu.bd</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faculty;
