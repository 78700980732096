import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

const AddTeachers = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [philosophy, setPhilosophy] = useState("");
  const [skills, setSkills] = useState("");

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("honours", data.honours);
    if (data.masters) {
      formData.append("masters", data.masters);
    }
    if (data.bed) {
      formData.append("bed", data.bed);
    }
    if (data.phd) {
      formData.append("phd", data.phd);
    }
    formData.append("experience", data.experience);
    formData.append("philosophy", data.philosophy);
    formData.append("skills", data.skills);
    formData.append("image", data.image[0]);
    //http://server.sjs.edu.bd/
    //https://server.sjs.edu.bd
    fetch("http://server.sjs.edu.bd/teachersData", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.insertedId) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Teacher Info Has Been Added",
            showConfirmButton: false,
            timer: 2000,
          });
          reset();
          setPhilosophy("");
          setSkills("");
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Unable to add teacher info",
          text: `${error} occurred.`,
        });
      });
  };
  return (
    <div className="sm:max-w-xl lg:max-w-4xl sm:w-5/6 mx-auto font-inter">
      <h2 className="my-3 text-3xl font-black text-center">
        Add New Teacher Info
      </h2>
      <div className="border rounded-lg border-slate-400">
        <form
          className="mb-0 space-y-1 w-5/6 mx-auto"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="p-2">
            <label htmlFor="name" className="label-style">
              Teacher Name:
              <sup className="text-red-600 no-underline">*</sup>
            </label>
            <div className="mt-1">
              <input
                id="name"
                type="text"
                className="input-style-green"
                {...register("name", { required: true })}
              />
              {errors.name && (
                <p className="text-red-500 mt-2">
                  *Please Enter the name of the teacher.
                </p>
              )}
            </div>
          </div>
          <div className="p-2">
            <label htmlFor="email" className="label-style">
              Teacher Email:
              <sup className="text-red-600 no-underline">*</sup> (Official
              Email)
            </label>
            <div className="mt-1">
              <input
                id="email"
                type="email"
                className="input-style-green"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <p className="text-red-500 mt-2">
                  *Please enter the email address.
                </p>
              )}
            </div>
          </div>
          <div className="p-2">
            <label htmlFor="honours" className="label-style">
              Honours:<sup className="text-red-600 no-underline">*</sup>
            </label>
            <div className="mt-1">
              <input
                id="honours"
                type="text"
                className="input-style-green"
                {...register("honours", { required: true })}
              />
              {errors.honours && (
                <p className="text-red-500 mt-2">
                  *Please enter the honours degree.
                </p>
              )}
            </div>
          </div>
          <div className="p-2">
            <label htmlFor="masters" className="label-style">
              Masters:
            </label>
            <div className="mt-1">
              <input
                id="masters"
                type="text"
                className="input-style-green"
                {...register("masters")}
              />
            </div>
          </div>
          <div className="p-2">
            <label htmlFor="bed" className="label-style">
              B.Ed. :
            </label>
            <div className="mt-1">
              <input
                id="bed"
                type="text"
                className="input-style-green"
                {...register("bed")}
              />
            </div>
          </div>
          <div className="p-2">
            <label htmlFor="phd" className="label-style">
              PhD:
            </label>
            <div className="mt-1">
              <input
                id="phd"
                type="text"
                className="input-style-green"
                {...register("phd")}
              />
            </div>
          </div>
          <div className="p-2">
            <label htmlFor="experience" className="label-style">
              Experience At SJIS:
              <sup className="text-red-600 no-underline">*</sup>
            </label>
            <div className="mt-1">
              <input
                id="experience"
                type="text"
                className="input-style-green"
                {...register("experience", { required: true })}
              />
              {errors.experience && (
                <p className="text-red-500 mt-2">
                  *Please enter the experience.
                </p>
              )}
            </div>
          </div>
          <div className="p-2">
            <label htmlFor="philosophy" className="label-style">
              Teaching Philosophy:
              <sup className="text-red-600 no-underline">*</sup>
            </label>
            <div className="mt-1">
              <textarea
                id="philosophy"
                className="input-style-green h-32 md:h-40"
                value={philosophy}
                {...register("philosophy", {
                  required: true,
                  onChange: (e) => {
                    setPhilosophy(e.target.value);
                  },
                })}
              />
              {errors.philosophy && (
                <p className="text-red-500 mt-2">
                  *Please enter the teaching philosophy.
                </p>
              )}
            </div>
          </div>
          <div className="p-2">
            <label htmlFor="skills" className="label-style">
              Skills:<sup className="text-red-600 no-underline">*</sup>
            </label>
            <div className="mt-1">
              <textarea
                id="skills"
                className="input-style-green h-32 md:h-40"
                value={skills}
                {...register("skills", {
                  required: true,
                  onChange: (e) => {
                    setSkills(e.target.value);
                  },
                })}
              />
              {errors.skills && (
                <p className="text-red-500 mt-2">
                  *Please enter the skills of the teacher.
                </p>
              )}
            </div>
          </div>
          <div className="p-2">
            <label htmlFor="image" className="label-style">
              Teacher Image:
              <sup className="text-red-600 no-underline">*</sup>
            </label>
            <div className="mt-1">
              <input
                id="image"
                type="file"
                accept="image/*"
                className=" file:text-white file:cursor-pointer file:px-4 file:py-2 file:rounded-full file:bg-blue-500 file:hover:bg-blue-600 rounded-full"
                {...register("image", {
                  required: true,
                })}
              />
              {errors.image && (
                <p className="text-red-500 mt-2">
                  *Please enter the image of the teacher.
                </p>
              )}
            </div>
          </div>
          <div className="text-center">
            <input
              className="mb-2 bg-emerald-500 px-4 py-2 text-white rounded-lg hover:bg-emerald-600"
              type="submit"
              value="Add"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTeachers;
