import React from "react";
import { Link } from "react-router-dom";
import { MdEmail as Email } from "react-icons/md";

const TeacherData = ({ teacher }) => {
  const { _id, name, email, imagePath } = teacher;
  return (
    <div className="h-96 md:h-[26rem] xl:h-[30rem] w-80 xl:w-[22rem] mx-auto p-4 md:p-5 bg-white rounded-lg drop-shadow-xl space-y-2 mt-5">
      <img
        // src={`data:image/png;base64,${image}`}
        src={`https://server.sjs.edu.bd/${imagePath}`}
        alt={`${name}`}
        className="h-60 w-60 xl:h-80 xl:w-80 md:h-64 md:w-64 mx-auto rounded-full ring-2 ring-slate-900"
      />
      <div className="text-center font-merriweather">
        <div className="text-lg text-black font-semibold space-y-2 lg:space-y-3">
          <p>{name}</p>
          <div className="flex justify-center items-center">
            <Email className="text-3xl md:text-3xl mr-1" />
            <p>{email}</p>
          </div>
        </div>
        <Link to={`/teacherProfile/${_id}`}>
          <button className="mt-2 px-4 py-1 border rounded-full text-lg text-white bg-blue-600 hover:bg-blue-700">
            View Profile
          </button>
        </Link>
      </div>
    </div>
    //target={"_blank"}
  );
};

export default TeacherData;
