import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer/Footer";
import Navbar from "../Shared/Header/Navbar";
import { TiHomeOutline as Home } from "react-icons/ti";
import HolyCross from "../../../src/Assets/Congregation_of_Holy_Cross.png";

const VisionMission = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bg-gray-100">
        <Navbar />
        <div className="font-merriweather opacity-90 p-4 md:p-8 bg-white rounded-lg drop-shadow-xl space-y-5 m-5 text-lg font-base md:font-medium text-left md:text-justify">
          <p className="text-3xl text-center md:text-5xl my-2 font-semibold text-blue-900 mb-2">
            Vision
          </p>
          <p className="text-center text-xl md:text-2xl mb-2 font-lato">
            Educating the Mind and Heart of the Student.
          </p>
          <p className="text-3xl text-center md:text-5xl my-2 font-semibold text-blue-900 mb-2">
            Mission
          </p>
          <p className="text-xl md:text-2xl text-left md:text-justify leading-9 font-lato ">
            Motivated by the vision of the Congregation of Holy Cross, the
            School seeks to provide our student body with a well-rounded program
            of studies and activities aimed at the formation of character and
            the education of the heart as well as the mind.
          </p>
          <p className="text-3xl text-center md:text-5xl my-2 font-semibold text-blue-900 mb-2">
            Brothers of Holy Cross
          </p>
          <img
            className="h-48 md:h-64 w-48 md:w-64 mx-auto"
            src={HolyCross}
            alt="Holy Cross Brothers Logo"
          />
          <p className="text-xl md:text-2xl text-left md:text-justify leading-9 md:leading-10 font-lato">
            The Brothers of Holy Cross are members of an international
            congregation of Catholic religious brothers and priests who lead
            extraordinary lives by bringing hope to others. Devoted to God
            through our ministry and prayer, we strive to make a difference in
            the world by being present and available to the people and
            communities we serve. Although ministry is essential to the life of
            a brother or priest in Holy Cross, it does not define our life.
            Rather, it is our lifestyle that makes us unique – we take this
            extraordinary journey of faith together. Abroad or at home, Holy
            Cross men live in community. In good times or challenging times, we
            can always count on our fellow brothers for support and
            encouragement.
          </p>
        </div>
        <div className="flex justify-center mb-3 md:mb-5">
          <Link to="/">
            <button className="button-style">
              <Home className="text-xl mr-1" />
              Home
            </button>
          </Link>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default VisionMission;
