import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import {
  RiDashboardFill as Panel,
  RiPlayListAddLine as Add,
} from "react-icons/ri";
import { AiOutlineUserAdd as UserAdd } from "react-icons/ai";
import { FaRegEdit as Edit, FaUserEdit as EditUser } from "react-icons/fa";
import { HiOutlineMenuAlt3 as OpenMenu } from "react-icons/hi";
import { VscChromeClose as CloseMenu } from "react-icons/vsc";
import { MdEventAvailable as Event } from "react-icons/md";

const Dashboard = () => {
  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <>
      <button
        onClick={() => setMobileMenu(!mobileMenu)}
        className="lg:hidden block p-2 border-2 border-white focus:ring-4 ring-offset-1 ring-white transition duration-700 ease-in rounded-lg"
      >
        {mobileMenu ? (
          <CloseMenu className="text-3xl" />
        ) : (
          <OpenMenu className="text-3xl" />
        )}
      </button>

      <div className="flex">
        <div className="flex-col w-60 h-screen px-3 py-8 bg-white border-r dark:bg-gray-800 dark:border-gray-600 hidden lg:block">
          <h2 className="text-3xl font-semibold text-gray-800 dark:text-white text-center">
            <Link to="/"> Admin Panel</Link>
          </h2>

          <div className="flex flex-col justify-between flex-1 mt-6">
            <nav>
              <Link
                className="flex items-center px-4 py-2 text-gray-700 bg-gray-200 rounded-md dark:bg-gray-700 dark:text-gray-200"
                to="/"
              >
                <Panel className="text-2xl md:text-3xl " />
                <span className="mx-4 font-xl">Dashboard</span>
              </Link>

              <Link className="dashboard-menu-style" to="addNotice">
                <Add className="text-lg md:text-3xl" />

                <span className="sidebar-text-format">Add Notice</span>
              </Link>

              <Link className="dashboard-menu-style" to="manageNotices">
                <Edit className="text-lg md:text-3xl" />
                <span className="sidebar-text-format">Manage Notices</span>
              </Link>

              <Link className="dashboard-menu-style" to="addEvent">
                <Event className="text-lg md:text-3xl" />
                <span className="sidebar-text-format">Add Event</span>
              </Link>

              <Link className="dashboard-menu-style" to="manageEvents">
                <Edit className="text-lg md:text-3xl" />
                <span className="sidebar-text-format">Manage Events</span>
              </Link>

              <Link className="dashboard-menu-style" to="addTeachers">
                <UserAdd className="text-lg md:text-3xl" />
                <span className="sidebar-text-format">Add New Teacher</span>
              </Link>
              <Link className="dashboard-menu-style" to="manageTeachers">
                <EditUser className="text-lg md:text-3xl" />
                <span className="sidebar-text-format">Edit Teacher Info</span>
              </Link>
            </nav>
          </div>
        </div>
        {mobileMenu && (
          <div className="fixed z-20 flex-col w-64 h-screen px-3 py-8 bg-white border-r dark:bg-gray-800 dark:border-gray-600">
            <h2 className="text-2xl underline-offset-1 font-semibold text-gray-800 dark:text-white text-center">
              <Link to="/">Admin Panel</Link>
            </h2>

            <div className="flex flex-col justify-between flex-1 mt-6">
              <nav>
                <Link
                  className="flex items-center px-4 py-2 text-gray-700 bg-gray-200 rounded-md dark:bg-gray-700 dark:text-gray-200"
                  to="/"
                >
                  <Panel className="text-2xl md:text-3xl " />
                  <span className="mx-4 font-xl">Dashboard</span>
                </Link>

                <Link className="dashboard-menu-style" to="addNotice">
                  <Add className="text-lg md:text-3xl" />

                  <span className="sidebar-text-format">Add Notice</span>
                </Link>

                <Link className="dashboard-menu-style" to="manageNotices">
                  <Edit className="text-lg md:text-3xl" />
                  <span className="sidebar-text-format">Manage Notices</span>
                </Link>

                <Link className="dashboard-menu-style" to="addEvent">
                  <Event className="text-lg md:text-3xl" />
                  <span className="sidebar-text-format">Add Event</span>
                </Link>

                <Link className="dashboard-menu-style" to="manageEvents">
                  <Edit className="text-lg md:text-3xl" />
                  <span className="sidebar-text-format">Manage Events</span>
                </Link>

                <Link className=" dashboard-menu-style" to="addTeachers">
                  <UserAdd className="text-lg md:text-3xl" />
                  <span className="sidebar-text-format">Add New Teacher</span>
                </Link>
                <Link className=" dashboard-menu-style" to="manageTeachers">
                  <EditUser className="text-lg md:text-3xl" />
                  <span className="sidebar-text-format">Edit Teacher Info</span>
                </Link>
              </nav>
            </div>
          </div>
        )}
        <Outlet />
      </div>
    </>
  );
};

export default Dashboard;
