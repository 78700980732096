import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Faculty from "./Pages/Faculty/Faculty";
import AddTeachers from "./Pages/Dashboard/AddTeachers/AddTeachers";
import ManageTeachers from "./Pages/Dashboard/ManageTeachers/ManageTeachers";
import AddNotice from "./Pages/Dashboard/AddNotice/AddNotice";
import ManageNotices from "./Pages/Dashboard/ManageNotices/ManageNotices";
import NotFound from "./Pages/NotFound/NotFound";
import TeacherProfile from "./Pages/TeacherProfile/TeacherProfile";
import { QueryClient, QueryClientProvider } from "react-query";
import AboutSchool from "./Pages/AboutSchool/AboutSchool";
import UpdateTeacherInfo from "./Pages/Dashboard/UpdateTeacherInfo/UpdateTeacherInfo";
import NoticeDetails from "./Pages/NoticeDetails/NoticeDetails";
import AddEvent from "./Pages/Dashboard/AddEvent/AddEvent";
import ManageEvents from "./Pages/Dashboard/ManageEvents/ManageEvents";
import VisionMission from "./Pages/VisionMission/VisionMission";
import EventDetails from "./Pages/EventDetails/EventDetails";
import AppPrivacyPolicy from "./Pages/AppPrivacyPolicy/AppPrivacyPolicy";

const queryClient = new QueryClient();

/**
 * TODO: Implement the index argument of React router
 * TODO: Create a dashboard homepage.
 */
function App() {
  function External() {
    window.location.replace("https://portal.sjs.edu.bd/auth/login");
    return null;
  }
  function StudentSupport() {
    window.location.replace("https://studentsupport.sjs.edu.bd/");
    return null;
  }
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/dashboardAdmin"
              element={
                // <RequireAuth>

                <Dashboard />
                // </RequireAuth>
              }
            >
              <Route path="addTeachers" element={<AddTeachers />} />
              <Route path="manageTeachers" element={<ManageTeachers />} />
              <Route index path="addNotice" element={<AddNotice />} />
              <Route path="manageNotices" element={<ManageNotices />} />
              <Route path="addEvent" element={<AddEvent />} />
              <Route path="manageEvents" element={<ManageEvents />} />
            </Route>
            <Route path="/faculty" element={<Faculty />} />
            <Route path="/login" element={<External />} />
            <Route path="/teacherProfile/:id" element={<TeacherProfile />} />
            <Route path="/noticeDetails/:id" element={<NoticeDetails />} />
            <Route path="/eventDetails/:id" element={<EventDetails />} />
            <Route path="/aboutSchool" element={<AboutSchool />} />
            <Route path="/studentSupport" element={<StudentSupport />} />
            <Route path="/visionAndMission" element={<VisionMission />} />
            <Route path="/appPrivacyPolicy" element={<AppPrivacyPolicy />} />
            {/* <Route path="/loginAdmin" element={<LoginAdmin />} /> */}
            <Route
              path="updateTeacherInfo/:id"
              element={<UpdateTeacherInfo />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </>
  );
}

export default App;
