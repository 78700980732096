import React from "react";
import { Link } from "react-router-dom";
import { TiHomeOutline as Home } from "react-icons/ti";
import Navbar from "../Shared/Header/Navbar";
import Footer from "../Shared/Footer/Footer";

const AppPrivacyPolicy = () => {
  return (
    <>
      <div className="bg-gray-100">
        <Navbar />
        <h1 className="header-style border-b-none font-merriweather mb-0 mt-4">
          Privacy Policy for St. Joseph Portal
        </h1>
        <div className="font-merriweather p-5 md:p-7 bg-white rounded-lg shadow-xl space-y-5 m-5 text-lg font-base md:font-medium leading-loose text-left md:text-justify">
          <p className="text-2xl md:text-5xl text-center my-2 font-semibold text-blue-900 leading-tight pt-0">
            Privacy Policy
          </p>
          <p className="font-lato text-xl md:text-2xl leading-5 md:leading-6 tracking-normal text-center">
            Last updated: June 1, 2024
          </p>
          <p className="font-lato text-xl md:text-2xl leading-8 md:leading-10 tracking-normal text-justify">
            This Privacy Policy describes our policies and procedures on the
            collection, use, and disclosure of your information when you use the
            Service and tells you about your privacy rights and how the law
            protects you. We use Your Personal data to provide and improve the
            Service. Using the Service, you agree to the collection and use of
            information per this Privacy Policy.
          </p>
          <p className="text-3xl md:text-5xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Interpretation and Definitions
          </p>
          <p className="text-2xl md:text-3xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Interpretation
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in the
            singular or in the plural.
          </p>
          <p className="text-2xl md:text-3xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Definitions
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            For the purposes of this Privacy Policy:
            <ul className="list-disc list-inside">
              <li>
                <span className="font-semibold">Account </span>means an
                individual account created for You to access our Service or
                parts of our Service.
              </li>
              <li>
                <span className="font-semibold">Affiliate </span>means an entity
                that controls, is controlled by or is under common control with
                a party, where "control" means ownership of 50% or more of the
                shares, equity interest, or other securities entitled to vote
                for the election of directors or other managing authority.
              </li>
              <li>
                <span className="font-semibold">Application </span>means the
                software program provided by the Company downloaded by You on
                any electronic device, named St. Joseph Portal.
              </li>
              <li>
                <span className="font-semibold">Company </span>
                (referred to as either "the Company", "We", "Us" or "Our" in
                this Agreement) refers to Cloud Solution, 97 Asad Avenue,
                Mohammadpur, Dhaka - 1207.
              </li>
              <li>
                <span className="font-semibold">Country </span>
                refers to Bangladesh.
              </li>
              <li>
                <span className="font-semibold">Device </span>
                means any device that can access the Service, such as a
                computer, a cell phone, or a digital tablet.
              </li>
              <li>
                <span className="font-semibold">Personal Data </span>
                is any information that relates to an identified or identifiable
                individual.
              </li>
              <li>
                <span className="font-semibold">Service </span>
                refers to the Application
              </li>
              <li>
                <span className="font-semibold">Service Provider </span>
                means any natural or legal person who processes the data on
                behalf of the Company. It refers to third-party companies or
                individuals employed by the Company to facilitate the Service,
                to provide the Service on behalf of the Company, to perform
                services related to the Service, or to assist the Company in
                analyzing how the Service is used.
              </li>
              <li>
                <span className="font-semibold">Usage Data </span>
                refers to data collected automatically, either generated by the
                Service or from the Service infrastructure (for example, the
                duration of a page visit).
              </li>
              <li>
                <span className="font-semibold">You </span>
                mean the individual accessing or using the Service, the Company,
                or other legal entity on behalf of which such individual is
                accessing or using the Service, as applicable.
              </li>
            </ul>
          </p>
          <p className="text-3xl md:text-5xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Collecting and Using Your Personal Data
          </p>
          <p className="text-2xl md:text-4xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Types of Data Collected
          </p>
          <p className="text-2xl md:text-3xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Personal Data
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally, identifiable information may include, but
            is not limited to:
            <ul className="list-disc list-inside">
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
              <li>Address, State, Province, ZIP/Postal code, City</li>
              <li>Usage Data</li>
            </ul>
          </p>
          <p className="text-2xl md:text-3xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Usage Data
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            Usage Data is collected automatically when this Service is used.
            <p>
              Usage Data may include information such as Your Device's Internet.
              Protocol address (e.g., IP address), browser type, browser
              version, the pages of our Service that You visit, the time and
              date of Your visit, the time spent on those pages, unique device
              identifiers and other diagnostic data.
            </p>
            <p>
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile
              device's unique ID, the IP address of Your mobile device, Your
              mobile operating system, the type of mobile Internet browser You
              use, unique device identifiers and other diagnostic data.
            </p>
            <p>
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.
            </p>
          </p>
          <p className="text-2xl md:text-3xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Information Collected while Using the Application
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            While using Our Application, to provide features of Our Application,
            We may collect, with Your prior permission:
            <ul className="list-disc list-inside">
              <li>Information regarding your location.</li>
            </ul>
            We use this information to provide features of Our Service, to
            improve and customize Our Service. The information may be uploaded
            to the Company's servers and/or a Service Provider's server or
            simply stored on Your device. You can enable or disable access to
            this information anytime through Your Device settings.
          </p>
          <p className="text-2xl md:text-4xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Use of Your Personal Data
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            The Company may use Personal Data for the following purposes:
            <ul className="list-disc list-inside">
              <li>
                <span className="font-semibold">
                  To provide and maintain our Service,{" "}
                </span>
                including monitoring the usage of our Service.
              </li>
              <li>
                <span className="font-semibold">To Manage Your Account: </span>
                to manage Your registration as a user of the Service. The
                Personal Data You provide can give You access to different
                functionalities of the Service that are available to You as a
                registered user.
              </li>
              <li>
                <span className="font-semibold">
                  For the performance of a contract:{" "}
                </span>
                the development, compliance, and undertaking of the purchase
                contract for the products, items, or services You have purchased
                or of any other contract with Us through the Service.
              </li>
              <li>
                <span className="font-semibold">To Contact You: </span>
                To contact You by email, telephone calls, SMS, or other
                equivalent forms of electronic communication, such as a mobile
                application's push notifications regarding updates or
                informative communications related to the functionalities,
                products, or contracted services, including the security
                updates, when necessary or reasonable for their implementation.
              </li>
              <li>
                <span className="font-semibold">To provide You </span>
                with news, special offers, and general information about other
                goods, services, and events that we offer that are similar to
                those that you have already purchased or enquired about unless
                You have opted not to receive such information.
              </li>
              <li>
                <span className="font-semibold">To manage Your requests: </span>
                To attend and manage Your requests to Us.
              </li>
              <li>
                <span className="font-semibold">For business transfers: </span>
                We may use Your information to evaluate or conduct a merger,
                divestiture, restructuring, reorganization, dissolution, or
                another sale or transfer of some or all of Our assets, whether
                as a going concern or as part of bankruptcy, liquidation, or
                similar proceeding, in which Personal Data held by Us about our
                Service users is among the assets transferred.
              </li>
              <li>
                <span className="font-semibold">For other purposes: </span>
                We may use Your information for other purposes, such as data
                analysis, identifying usage trends, determining the
                effectiveness of our promotional campaigns, and evaluating and
                improving our Service, products, services, marketing, and your
                experience.
              </li>
            </ul>
            <br />
            We may share Your personal information in the following situations:
            <ul className="list-disc list-inside">
              <li>
                <span className="font-semibold">With Service Providers: </span>
                We may share Your personal information with Service Providers to
                monitor and analyze the use of our Service to contact You.
              </li>
              <li>
                <span className="font-semibold">For business transfers: </span>
                We may share or transfer Your personal information in connection
                with, or during negotiations of, any merger, sale of Company
                assets, financing, or acquisition of all or a portion of Our
                business to another company.
              </li>
              <li>
                <span className="font-semibold">With Affiliates: </span>
                We may share Your information with Our affiliates, which will
                require those affiliates to honor this Privacy Policy.
                Affiliates include Our parent company and any other
                subsidiaries, joint venture partners, or companies that We
                control or are under common control.
              </li>
              <li>
                <span className="font-semibold">With business partners: </span>
                We may share Your information with Our business partners to
                offer You certain products, services, or promotions.
              </li>
              <li>
                <span className="font-semibold">With other users: </span>
                When You share personal information or otherwise interact in
                public areas with other users, such information may be viewed by
                all users and may be publicly distributed outside.
              </li>
              <li>
                <span className="font-semibold">With Your consent: </span>
                We may disclose Your personal information for any other purpose.
              </li>
            </ul>
          </p>
          <p className="text-2xl md:text-4xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Retention of Your Personal Data
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period,
            except when this data is used to strengthen the security or improve
            the functionality of Our Service. We are legally obligated to retain
            this data for more extended periods.
          </p>
          <p className="text-2xl md:text-4xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Transfer of Your Personal Data
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country, or other
            governmental jurisdiction where the data protection laws may differ
            from those of Your jurisdiction.
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            The Company will take all necessary steps to ensure that Your data
            is treated securely and follows this Privacy Policy. No transfer of
            Your Personal Data will take place to an organization or a country
            unless adequate controls exist, including the security of Your data
            and other personal information.
          </p>
          <p className="text-2xl md:text-4xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Deletion of Your Personal Data
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            You have the right to delete or request that We assist in deleting
            the Personal Data we have collected about You.{" "}
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            Our Service may allow You to delete certain information about You
            from within the Service.
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            You may update, amend, or delete Your information at any time by
            signing in to Your Account, if you have one, and visiting the
            account settings section that allows you to manage Your personal
            data. You may also contact Us to request access to, correct, or
            delete any personal information you have provided.
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            Please note, however, that We may need to retain certain information
            when we have a legal obligation or lawful basis.
          </p>
          <p className="text-2xl md:text-4xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Disclosure of Your Personal Data
          </p>
          <p className="text-2xl md:text-3xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Business Transactions
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            If the Company is involved in a merger, acquisition, or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and subject to a different Privacy
            Policy.
          </p>
          <p className="text-2xl md:text-3xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Law enforcement
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required by law or in response to valid
            requests by public authorities (e.g., a court or a government
            agency).
          </p>
          <p className="text-2xl md:text-3xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Other legal requirements
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
            <ul className="list-disc list-inside">
              <li>Comply with a legal obligation.</li>
              <li>Protect and defend the rights or property of the Company.</li>
              <li>
                Prevent or investigate possible wrongdoing in connection with
                the Service.
              </li>
              <li>
                Protect the personal safety of Users of the Service or the
                public.
              </li>
              <li>Protect against legal liability.</li>
            </ul>
          </p>
          <p className="text-2xl md:text-4xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Security of Your Personal Data
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            We use SSLCOMMERZ for processing payments. We/SSLCOMMERZ do not
            store your card data on their servers. The data is encrypted through
            the Payment Card Industry Data Security Standard (PCI-DSS) when
            processing payments.
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            Your purchase transaction data is only used as long as necessary to
            complete your purchase transaction. After that is complete, your
            purchase transaction information is not saved.
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            For more insight, you may also want to read the terms and conditions
            of SSLCOMMERZ at https://www.sslcommerz.com/.
          </p>
          <p className="text-2xl md:text-3xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Payment
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            The security of Your Personal Data is important to Us, but remember
            that no Payment method of transmission over the Internet or method
            of electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
          <p className="text-2xl md:text-4xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Children's Privacy
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under 13. If You are a parent or guardian and know that Your child
            has provided Us with Personal Data, please contact Us. Suppose We
            become aware that We have collected Personal Data from anyone under
            the age of 13 without verification of parental consent. In that
            case, We take steps to remove that information from Our servers.
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            Suppose We need to rely on consent as a legal basis for processing
            Your information and Your country requires permission from a parent.
            In that case, We may require Your parent's consent before We collect
            and use that information.
          </p>
          <p className="text-2xl md:text-4xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Links to Other Websites
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            Our Service may contain links to other websites not operated by us.
            If You click on a third-party link, You will be directed to that
            third party's site. We strongly advise You to review the Privacy
            Policy of every site You visit.
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            We have no control over and assume no responsibility for the
            content, privacy policies, or practices of any third-party sites or
            services.
          </p>
          <p className="text-2xl md:text-4xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0"></p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            We will inform you via email and/or a prominent notice on Our
            Service before the change becomes effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            You are advised to review this Privacy Policy periodically for any
            changes. These Privacy Policy changes are effective when posted on
            this page.
          </p>
          <p className="text-2xl md:text-4xl text-center my-1 font-semibold text-blue-900 leading-tight pt-0">
            Contact Us
          </p>
          <p className="font-lato text-xl md:text-2xl leading-9 md:leading-10 tracking-normal">
            If you have any questions about this Privacy Policy, You can contact
            us by email at:{" "}
            <span className="font-semibold">sjisweb@gmail.com</span>
          </p>
        </div>
        <div className="flex justify-center md:mb-5">
          <Link to="/">
            <button className="button-style">
              <Home className="text-xl mr-1" />
              Home
            </button>
          </Link>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AppPrivacyPolicy;
